import * as Yup from 'yup';
import 'yup-phone';

const CreateOnBoardApplicationValidation = Yup.object().shape({
  firstname: Yup.string('Enter Firstname').required('Firstname is required'),
  lastname: Yup.string('Enter Lastname').required('Lastname is required'),
  email: Yup.string('Enter Email')
    .email('Enter a valid Email')
    .required('Email is required'),
  contactNo: Yup.string().label('Contact no.')
    .min(10, 'Phone number is too short, please enter a valid 10-digit number')
    .max(10, 'Phone number is too long, please enter a valid 10-digit number')
    .phone('Please enter a valid 10-digit phone number')
    .required('Phone number is required'),
});

export default CreateOnBoardApplicationValidation;
