import { useEffect, useState } from 'react';
import { Divider, Skeleton } from '@mui/material';
import {
  CardActionFooter,
  CardContainer,
  CardHead,
} from '../../../../style/components/card.styled';

const UserListSkeleton = (props) => {
  const [skeletonCount, setSkeletonCount] = useState(0);
  useEffect(() => {
    if (props.resolutionController > 1200) {
      setSkeletonCount(9);
    } else if (
      props.resolutionController > 800
      && props.resolutionController < 1200
    ) {
      setSkeletonCount(6);
    } else if (
      props.resolutionController > 500
      && props.resolutionController < 799
    ) {
      setSkeletonCount(6);
    } else if (
      props.resolutionController > 320
      && props.resolutionController < 499
    ) {
      setSkeletonCount(3);
    } else {
      setSkeletonCount(6);
    }
  }, []);
  return Array.from({ length: skeletonCount }).map((data, index) => (
    <div key={!data && index}>
      <CardContainer leftborder="true">
        {/* <Skeleton animation="wave" height={20} width="20%" /> */}

        <div className="testDesign">
          <div>
            <CardHead
              avatar={(
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={50}
                  height={50}
                />
              )}
              title={(
                <Skeleton
                  animation="wave"
                  height={15}
                  width="60%"
                  style={{ marginBottom: 6 }}
                />
              )}
              action={(
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={25}
                  height={25}
                  style={{ marginTop: 12, marginRight: 6 }}
                />
              )}
              subheader={<Skeleton animation="wave" height={15} width="30%" />}
            />
          </div>
        </div>

        {/* <CardHead
          avatar={
            <Skeleton
              animation='wave'
              variant='circular'
              width={50}
              height={50}
            />
          }
          title={
            <Skeleton
              animation='wave'
              height={15}
              width='60%'
              style={{ marginBottom: 6 }}
            />
          }
          subheader={<Skeleton animation='wave' height={15} width='30%' />}
        /> */}
        <Divider />
        <CardActionFooter>
          <Skeleton animation="wave" height={30} width="20%" />
          <Skeleton animation="wave" height={30} width="20%" />
        </CardActionFooter>
      </CardContainer>
    </div>
  ));
};
export default UserListSkeleton;
