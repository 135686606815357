import styled from '@emotion/styled';
import {
  Card,
  CardHeader,
  Fab,
  Paper,
  Typography,
} from '@mui/material';
import { Form } from 'formik';

const CreateAppForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 1rem;
  gap: 2rem;

  @media only screen and (max-width: 500px) {
    .hide {
      display: none;
    }
  }
`;

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

const FormAction = styled.span`
  & > button {
  }
`;

const CreateAppItem = styled.div`
  width: 100%;
`;

const Title = styled(Typography)`
  font-size: 1.2rem;
`;

const DetailsWrapper = styled(Card)`
  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: ${({ theme }) => theme.shadows[0]};
  padding: 1rem;
`;

const DetailsHeader = styled(CardHeader)`
  padding: 0;
  margin-bottom: 2rem;

  span {
    font-size: 1.2rem;
  }
`;

const AppCreateBtn = styled(Fab)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  position: absolute;
  bottom: 3rem;
  right: 3rem;

  &:hover {
    background-color: #0369c9;
  }
`;

const ViewContainer = styled(Paper)`
  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: ${({ theme }) => theme.shadows[0]};
  padding: 1rem;
`;

const ViewContent = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

const AppCreatedAt = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: gray;
`;

const FormFieldWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem 2rem;

  @media only screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`;

export {
  CreateAppForm,
  FormWrapper,
  CreateAppItem,
  Title,
  DetailsWrapper,
  DetailsHeader,
  FormAction,
  AppCreateBtn,
  ViewContainer,
  ViewContent,
  AppCreatedAt,
  FormFieldWrap,
};
