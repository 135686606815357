import { Button as MUIButton } from '@mui/material';
import PropTypes from 'prop-types';

const Button = (props) => {
  const {
    children = '',
    id = '',
    type = 'submit',
    variant = 'contained',
    disabled = false,
  } = props;
  return (
    <MUIButton id={id} type={type} variant={variant} disableElevation disabled={disabled}>
      {children}
    </MUIButton>
  );
};

Button.propTypes = {
  children: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Button;
