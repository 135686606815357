import { Box } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const FlexedBox = (props) => {
  const {
    direction = null,
    wrap = null,
    justifyContent = null,
    alignContent = null,
    alignItems = null,
    gap = null,
    marginLeft = 0,
    children = null,
  } = props;
  return (
    <Box
      display="flex"
      flexDirection={direction}
      flexWrap={wrap}
      justifyContent={justifyContent}
      alignContent={alignContent}
      alignItems={alignItems}
      gap={gap}
      ml={marginLeft}
    >
      {children}
    </Box>
  );
};

FlexedBox.propTypes = {
  direction: PropTypes.string,
  wrap: PropTypes.string,
  justifyContent: PropTypes.string,
  alignContent: PropTypes.string,
  alignItems: PropTypes.string,
  gap: PropTypes.string,
  marginLeft: PropTypes.number,
  children: PropTypes.node,
};

// FlexedBox.defaultProps = {
//   direction: null,
//   wrap: null,
//   justifyContent: null,
//   alignContent: null,
//   alignItems: null,
//   gap: null,
//   marginLeft: 0,
//   children: null,
// };

export default FlexedBox;
