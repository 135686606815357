import { SnackbarProvider } from 'notistack';
import { Route, Routes } from 'react-router-dom';
import { Global } from '@emotion/react';
import GlobalStyle from '../style/base/global.style';
import HeaderSeaction from './layouts/Header';
import Main from './layouts/Main';
import Login from './pages/login/Login';
import Error from './pages/error/Error';
import EmailScreen from './pages/login/EmailScreen';
import NewPassWord from './pages/login/NewPassWord';

const App = () => (
  <SnackbarProvider maxSnack={3}>
    <Global styles={GlobalStyle} />
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/resest-password" element={<EmailScreen />} />
      <Route path="/add-new-password" element={<NewPassWord />} />
      <Route
        path="*"
        element={(
          <>
            <HeaderSeaction />
            <Main />
          </>
        )}
      />
      <Route path="/error" element={<Error />} />
    </Routes>
  </SnackbarProvider>
);

export default App;
