// Importing Form and Formik from Formik library for form handling
import { Form, Formik } from 'formik';
// Importing React and useState from React library for managing component state
import React, { useState } from 'react';
// Importing Yup for form validation
import * as Yup from 'yup';
// Importing FormHelperText component from Material-UI
import { FormHelperText } from '@mui/material';
// Importing custom Button component
import Button from '../../components/inputs/Button.input';
// Importing custom LoadingSpinner component
import LoadingSpinner from '../../components/Loader/LoadingSpinner';
// Importing resetPasswordAPI function from API file
import { resetPasswordAPI } from '../../../apis/apis';
// Importing showSnackbar function for displaying notifications
import showSnackbar from '../../components/Message/showSnackbar';
// Importing custom PasswordField component
import { PasswordFiled } from '../../components/inputs/PasswordFiled';

// ResetPassword component definition
const ResetPassword = () => {
  // State variables for managing loading state and password visibility toggles
  const [loading, setLoading] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Define validation schema using Yup
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Current password is required'),
    newPassword: Yup.string().required('New password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm password is required'),
  });

  // Handle form submission
  const handleSubmit = async (values, { resetForm }) => {
    // Set loading state to true
    setLoading(true);
    // Call resetPasswordAPI function with form values
    await resetPasswordAPI(values)
      .then((res) => {
        // If response status is 200 (success)
        if (res.status === 200) {
          // If response contains a message
          if (res?.data?.message) {
            // Show success message
            showSnackbar(res.data.message, { variant: 'success' });
          }
          // Reset form fields
          resetForm();
        }
      }).catch((err) => {
        // If error response contains a message
        if (err?.response?.data?.message) {
          // Show error message
          showSnackbar(err?.response?.data?.message, { variant: 'error' });
        }
      });
    // Set loading state to false
    setLoading(false);
  };

  // Render the component
  return (
    <Formik
      initialValues={{
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        touched,
        errors,
      }) => (
        <Form className="profile-edit-detail">
          <div className="profile-edit-title reset-password-title">
            <span>Security</span>
          </div>
          {/* Current Password field */}
          <PasswordFiled
            id="cpassword"
            label="Current Password"
            placeholder="Current Password"
            name="currentPassword"
            variant="outlined"
            value={values.currentPassword}
            error={touched.currentPassword && errors.currentPassword}
            helperText={touched.currentPassword && errors.currentPassword}
            onChange={handleChange}
            iconOnClick={() => setShowCurrentPassword(!showCurrentPassword)}
            mode={showCurrentPassword}
            errorMessage={(
              <FormHelperText style={{ minHeight: '24px' }} error>
                {touched.currentPassword && errors.currentPassword}
              </FormHelperText>
            )}
          />
          {/* New Password field */}
          <PasswordFiled
            id="newpassword"
            label="New Password"
            placeholder="New Password"
            name="newPassword"
            variant="outlined"
            value={values.newPassword}
            error={touched.newPassword && errors.newPassword}
            helperText={touched.newPassword && errors.newPassword}
            onChange={handleChange}
            iconOnClick={() => setShowNewPassword(!showNewPassword)}
            mode={showNewPassword}
            errorMessage={(
              <FormHelperText style={{ minHeight: '24px' }} error>
                {touched.newPassword && errors.newPassword}
              </FormHelperText>
            )}
          />
          {/* Confirm Password field */}
          <PasswordFiled
            id="confirmpassword"
            label="Confirm Password"
            placeholder="Confirm Password"
            name="confirmPassword"
            variant="outlined"
            value={values.confirmPassword}
            error={touched.confirmPassword && errors.confirmPassword}
            helperText={touched.confirmPassword && errors.confirmPassword}
            onChange={handleChange}
            iconOnClick={() => setShowConfirmPassword(!showConfirmPassword)}
            mode={showConfirmPassword}
            errorMessage={(
              <FormHelperText style={{ minHeight: '24px' }} error>
                {touched.confirmPassword && errors.confirmPassword}
              </FormHelperText>
            )}
          />
          {/* Save button */}
          <div className="profile-edit-action">
            <Button
              disabled={loading}
            >
              {loading && <LoadingSpinner />}
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPassword;
