import PropTypes from 'prop-types';

import logoImg from './company-logo.png';
import underMaintenanceImg from './under-maintenance.png';

const Logo = ({ style }) => (
  <img
    className={'nav-logo'}
    src={logoImg}
    alt="CT Logo"
  />
);

const UnderMaintenance = ({ style }) => (
  <img className={style} src={underMaintenanceImg} alt="Under Maintenance" />
);

Logo.propTypes = {
  style: PropTypes.string,
};

UnderMaintenance.propTypes = {
  style: PropTypes.string,
};

export { Logo, UnderMaintenance };
