import * as Yup from 'yup';

const createUserFormValidation = Yup.object({
  firstname: Yup.string('Enter Firstname').required('Firstname is required'),
  lastname: Yup.string('Enter Lastname').required('Lastname is required'),
  email: Yup.string('Enter Email')
    .email('Enter a valid Email')
    .required('Email is required'),
  password: Yup.string().test({
    name: 'is-sku',
    skipAbsent: true,
    test(value, context) {
      if (context.parent.passwordController) {
        if (!value) {
          return context.createError({
            message: "Can't left password field empty ",
          });
        }
      }
      return true;
    },
  }),
  // role: Yup.string('Enter Role').required('Role is required'),
});

export default createUserFormValidation;
