import axios from 'axios';
import { isEmpty } from 'lodash';
import { apiErrData, apiResData } from './func/ResHandler';

const apiUrl = process.env.REACT_APP_ADMIN_API_URL;

const api = axios.create({
  // baseURL: 'http://loc.dev.onboarding-admin.ctserver.space:3000',
  baseURL: apiUrl,
  // baseURL: 'http://sit.onboarding-admin.ctserver.space:4000',
  // baseURL: 'http://dev.onboarding-admin.ctserver.space:3000',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
  // timeout: 1000,
});

/*
NIU - Not In Use
DEH - Done Error Handling
Done__A - After
Done__B - Before
*/

/* NIU Not In Use */
const isServerUp = async () => {
  const config = {
    method: 'get',
    url: '/',
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

/* DEH Error handling - Done_A */
const isLoggedinApi = async () => {
  const config = {
    method: 'get',
    url: '/is-loggedin',
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

/* DEH Error handling - Done_B */
const loginApi = async (apiArgs) => {
  const config = {
    method: 'post',
    url: '/login',
    data: apiArgs,
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

/* DEH Error handling - Done__A */
const logoutApi = async () => {
  const config = {
    method: 'get',
    url: '/logout',
  };

  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

/* NIU Not In Use - Check Use of API Before removal */
const roleChoiceApi = async (args) => {
  const config = {
    method: 'get',
    url: isEmpty(args) ? '/role/choice' : `/role/choice?${args}`,
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

/* DEH Error handling - Done_B */
const dashboard = async () => {
  const config = {
    method: 'get',
    url: '/dashboard',
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));
  return apiRes;
};

/* DEH Error handling - Done__B */
const createOnBoardAppApi = async (apiArgs) => {
  const config = {
    method: 'post',
    url: '/application',
    data: apiArgs,
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));
  return apiRes;
};

/* NIU Not In Use - Check Use of API Before removal */
const updateApplicationDetails = async (apiArgs) => {
  const config = {
    method: 'patch',
    url: '/application',
    data: apiArgs,
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));
  return apiRes;
};

/** ──────────────────────────────────────────────
 *    ## Get Application list
 */

const getAppApi = async (apiArgs) => {
  const config = {
    method: 'get',
    url: `/application/get-app-data?applicationId=${apiArgs}`,
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));
  return apiRes;
};

const appListApi = async (apiArgs) => {
  const config = {
    method: 'get',
    url: isEmpty(apiArgs) ? '/application' : `/application?${apiArgs}`,
    // url: '/application?test=10&test=20&test=30',
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));
  return apiRes;
};

const appKeyApi = async (apiArgs) => {
  const config = {
    method: 'get',
    url: '/application-key',
    params: apiArgs,
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));
  return apiRes;
};

const sendAppToCandidateApi = async (apiArgs) => {
  const config = {
    method: 'post',
    url: '/send-application',
    data: apiArgs,
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));
  return apiRes;
};

const resendAppToCandidateApi = async (apiArgs) => {
  const config = {
    method: 'post',
    url: '/resend-application',
    params: apiArgs,
  };
  const apiRes = api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));
  return apiRes;
};

const updateAppStatusApi = async (apiArgs) => {
  const config = {
    method: 'patch',
    url: '/application/update-status',
    data: apiArgs,
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));
  return apiRes;
};

const restoreAppApi = async (apiArgs) => {
  const config = {
    method: 'patch',
    url: '/application/update-status',
    params: apiArgs,
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));
  return apiRes;
};

const deleteAppApi = async (apiArgs) => {
  const config = {
    method: 'delete',
    url: '/application',
    params: apiArgs,
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));
  return apiRes;
};

const viewCandidateAppApi = async (apiArgs) => {
  //   const { appId, candidateId } = apiArgs;
  const config = {
    method: 'get',
    url: '/view-onboard-application',
    params: apiArgs,
    // params: { appId, candidateId },
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

const getAppStatusChoiceApi = async () => {
  const config = {
    method: 'get',
    url: '/application/status-choice',
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));
  return apiRes;
};

const createUserApi = async (apiArgs) => {
  const config = {
    method: 'userId' in apiArgs && !isEmpty(apiArgs.userId) ? 'patch' : 'post',
    url: '/user',
    data: apiArgs,
  };

  const apiRes = api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

const updateUserApi = async (apiArgs) => {
  const config = {
    method: 'patch',
    url: '/user',
    data: apiArgs,
  };

  const apiRes = api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

const userListApi = async (apiArgs) => {
  const config = {
    method: 'get',
    url: isEmpty(apiArgs) ? '/user' : `/user?${apiArgs}`,
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

const userGetApi = async (id) => {
  const config = {
    method: 'get',
    url: `/user/${id}`,
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

const userDeleteApi = async (id) => {
  const config = {
    method: 'delete',
    url: `/user/${id}`,
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

const createdByApi = async () => {
  const config = {
    method: 'get',
    url: '/user/options/created-by',
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

const candidateListApi = async (props) => {
  const config = {
    method: 'get',
    url: `/user/options/candidates?name=${props}`,
  };
  const apiRes = await api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

const chnagePasswordRequestApi = async (apiArgs) => {
  const config = {
    method: 'post',
    url: '/change-password',
    data: apiArgs,
  };
  const apiRes = api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

const addNewPassword = async (apiArgs) => {
  const config = {
    method: 'post',
    url: '/change-password/add-new-password',
    data: apiArgs,
  };
  const apiRes = api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

const verifyPasswordLink = async (apiArgs) => {
  const config = {
    method: 'get',
    url: '/change-password/verify-link',
    params: apiArgs,
  };
  const apiRes = api(config)
    .then((res) => apiResData(res))
    .catch((err) => apiErrData(err));

  return apiRes;
};

const adminProfileAPI = async () => {
  const config = {
    method: 'get',
    url: '/profile',
  };
  return api(config);
};
const adminUpdateProfileAPI = async (apiArgs) => {
  const config = {
    method: 'patch',
    url: '/profile',
    data: apiArgs,
  };
  return api(config);
};

const updateAdminProfileImage = async (apiArgs) => {
  const config = {
    method: 'patch',
    url: '/profile/profile-image',
    data: apiArgs,
  };
  return api(config);
};

const resetPasswordAPI = async (apiArgs) => {
  const config = {
    method: 'post',
    url: '/reset-password',
    data: apiArgs,
  };
  return api(config);
};

/**
 * Not Userd
 * - viewCandidateAppApi,
 */

export {
  isServerUp,
  isLoggedinApi,
  loginApi,
  logoutApi,
  roleChoiceApi,
  dashboard,
  createOnBoardAppApi,
  updateApplicationDetails,
  getAppApi,
  appListApi,
  appKeyApi,
  sendAppToCandidateApi,
  resendAppToCandidateApi,
  updateAppStatusApi,
  restoreAppApi,
  deleteAppApi,
  viewCandidateAppApi,
  getAppStatusChoiceApi,
  createUserApi,
  updateUserApi,
  userGetApi,
  userListApi,
  userDeleteApi,
  createdByApi,
  candidateListApi,
  chnagePasswordRequestApi,
  addNewPassword,
  verifyPasswordLink,
  adminProfileAPI,
  adminUpdateProfileAPI,
  updateAdminProfileImage,
  resetPasswordAPI,
};
