import * as Yup from 'yup';

const loginValidationSchema = Yup.object({
  email: Yup.string('')
    .required('Please provide your email address !')
    .email('Enter a valid email address !'),
  password: Yup.string('').required('Please provide your password !'),
  //   .min(6, 'Password is too short - should be 8 chars minimum.')
  //     .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
});

export default loginValidationSchema;
