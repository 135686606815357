import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import PropTypes from 'prop-types';

const DilogControl = (props) => {
  const {
    open,
    maxWidth,
    fullWidth,
    title,
    dialogActions,
    children,
  } = props;

  return (
    <Dialog open={open} fullWidth={fullWidth} maxWidth={maxWidth}>
      <DialogTitle>{title}</DialogTitle>
      {!children && <DialogActions>{dialogActions}</DialogActions>}
      {children && <DialogContent dividers>{children}</DialogContent>}
      {children && <DialogActions>{dialogActions}</DialogActions>}
    </Dialog>
  );
};

DilogControl.propTypes = {
  open: PropTypes.bool,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
  title: PropTypes.node,
  dialogActions: PropTypes.node,
  children: PropTypes.node,
};

// DilogControl.defaultProps = {
//   open: false,
//   maxWidth: 'md',
//   fullWidth: false,
//   title: null,
//   dialogActions: null,
//   children: null,
// };

export default DilogControl;
