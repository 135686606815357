/* eslint-disable react/prop-types */
import { Visibility, VisibilityOff } from '@mui/icons-material';
import PropTypes from 'prop-types';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export const PasswordFiled = ({
  label = '',
  error = false,
  mode = false,
  id = '',
  name = '',
  value = '',
  onChange = () => { },
  iconOnClick = () => { },
  errorMessage = null,
  disabled = false,
}) => (
  <FormControl variant="outlined">
    <InputLabel error={error}>{label}</InputLabel>
    <OutlinedInput
      variant="outlined"
      type={mode ? 'text' : 'password'}
      id={id}
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      error={error}
      disabled={disabled}
      endAdornment={(
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={iconOnClick}
            edge="end"
            id="iconButtonId"
            name="iconButtonName"
          >
            {mode ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      )}
    />
    {errorMessage}
  </FormControl>
);
export default PasswordFiled;
PasswordFiled.propTypes = {
  label: PropTypes.string,
  error: PropTypes.bool,
  mode: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  iconOnClick: PropTypes.func,
  errorMessage: PropTypes.node,
  onChange: PropTypes.func,
};
