import { TextField as MUITextField, FormHelperText } from '@mui/material';
import PropTypes from 'prop-types';

const TextField = (props) => {
  const {
    id = 'TextFieldId',
    type = 'text',
    placeholder = '',
    label = '',
    name = '',
    value = '',
    onChange = () => { },
    onBlur = () => { },
    variant = 'standard',
    errors = '',
    touched = false,
    size = 'medium',
    serverException = '',
    className,
    disabled = false,
    isHelperText = true,
  } = props;
  return (
    <MUITextField
      variant={variant}
      id={id}
      type={type}
      placeholder={placeholder}
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={(touched && Boolean(errors)) || Boolean(serverException)}
      // helperText={(touched && errors) || serverException}
      helperText={
        isHelperText ? (
          <FormHelperText style={{ minHeight: '24px', marginLeft: '0px', fontSize: '0.80rem' }}>
            {(touched && errors) || serverException}
          </FormHelperText>
        ) : null
      }
      size={size}
      className={className}
      disabled={disabled}
    />
  );
};

TextField.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  variant: PropTypes.string,
  errors: PropTypes.string,
  touched: PropTypes.bool,
  size: PropTypes.string,
  serverException: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
};

// TextField.defaultProps = {
//   id: 'TextFieldId',
//   type: 'text',
//   placeholder: 'Placeholder',
//   label: 'CT',
//   name: 'CT',
//   value: '',
//   onChange: () => {},
//   variant: 'standard',
//   errors: '',
//   touched: false,
//   size: 'medium',
//   serverException: '',
//   className: undefined,
//   disabled: false,
//   onBlur: () => {},
// };

export default TextField;
