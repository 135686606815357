import Navbar from '../components/navbar/Navbar';
import Container from '../../style/base/container.style';
import Header from './Header.style';

const HeaderSeaction = () => (
  <Header>
    <Container>
      <Navbar />
    </Container>
  </Header>
);

export default HeaderSeaction;
