import { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import CountCard from '../../components/surface/CountCard.surface';
import { dashboard } from '../../../apis/apis';
import error404 from '../../../img/svg/error404.svg';
// import noConnectionImg from '../../../img/svg/no-connection.svg';
import { ErrorImg, ErrorMessage } from '../error/error.style';
import { MyContext } from '../../context/context';

const Dashboard = () => {
  const [dashboardCountCardState, setDashboardCountCardState] = useState({
    totalApplications: 10,
    totalCandidates: 0,
    totalHrs: 0,
  });
  const [loading, setLoading] = useState(true);
  const { appError, setAppError } = useContext(MyContext);

  const handleDashboardAPIs = async () => {
    const dashboardData = await dashboard();
    const { success, data } = dashboardData;

    if (success) {
      setDashboardCountCardState((prevProps) => ({
        ...prevProps,
        totalApplications: data.totalApplications,
        totalHrs: data.totalHrs,
        totalCandidates: data.totalCandidates,
      }));
      setLoading(false);
    } else {
      setAppError(() => ({
        isFailed: true,
        errMessage: dashboardData.message,
        statusCode: dashboardData.status,
      }));
      setLoading(true);
    }

    /* Testing */
    /* Testing */
  };

  useEffect(() => {
    handleDashboardAPIs();
  }, []);

  return (
    <div>
      {!loading && (
        <Grid container spacing={1} sx={{ p: 1 }}>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <CountCard
              count={dashboardCountCardState.totalApplications}
              countTitle="Applications"
              iconSrc={<ArticleOutlinedIcon />}
            />
          </Grid>
          {dashboardCountCardState.totalHrs ? (
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <CountCard
                count={dashboardCountCardState.totalHrs}
                countTitle="HRs"
                iconSrc={<SupervisedUserCircleOutlinedIcon />}
              />
            </Grid>
          ) : null}
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <CountCard
              count={dashboardCountCardState.totalCandidates}
              countTitle="Candidates"
              iconSrc={<BadgeOutlinedIcon />}
            />
          </Grid>
        </Grid>
      )}
      {(appError.statusCode === 404 || appError.statusCode === 500) && (
        <div className="error-snippet">
          <ErrorImg src={error404} alt="not found" />
          <ErrorMessage>{appError.errMessage}</ErrorMessage>
        </div>
      )}
      {/* {appError.statusCode === 0 && <ErrorImg src={noConnectionImg} alt="no connection" />} */}
    </div>
  );
};

export default Dashboard;
