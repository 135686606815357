import React from 'react';
import { ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { MyProvider } from './template/context/context';
import App from './template/App';
import theme from './style/base/theme.style';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <MyProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </MyProvider>
  </BrowserRouter>,
);
