import { css } from '@emotion/react';

const GlobalStyle = css`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  body {
    background-color: #f5f5f5;
    color: black;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  img {
    width: 100%;
    height: auto;
  }
  section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-block: 1rem;
  }
  .flex-col {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  /** ─ Grid ──────────────────────────────────── */
  .d-grid,
  .col-1-span-2 {
    display: grid;
  }

  .col-1-span-2 {
    grid-column: 1 / span 2;
  }

  /** ─ min-width:  ───────────────────────────── */
  .min-w-180 {
    min-width: 180px !important;
  }

  /** ─ Card ───────────────────────────── */
  .card-bg {
    position: relative;
  }
  .card-bg::before {
    content: '';
    position: absolute;
    width: 210px;
    height: 210px;
    background: linear-gradient(
      140.9deg,
      #90caf9 -14.02%,
      rgba(144, 202, 249, 0) 77.58%
    );
    border-radius: 50%;
    top: -160px;
    right: -130px;
  }
  .error-snippet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .flex-row-reverse {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
  }
  .flex-space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .overflow-hidden-text {
    width: 75%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .grid-column-50-2 {
    display: grid;
    grid-template-columns: 50% 50%;
    white-space: nowrap;
    width: 100%;
    gap: 0.5rem;
  }
  .flexing-content-end {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
    width: 100%;
  }
  .MuiAutocomplete-inputRoot{
    display:flex !important;
    flex-wrap:nowrap !important;
    overflow:hidden;
  }
`;

export default GlobalStyle;
