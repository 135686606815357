import PropTypes from 'prop-types';
import { Divider, Typography } from '@mui/material';
import {
  CSSCardIcon,
  CSSCountCard,
  CSSCountCardBody,
  CSSCountCardBodyItem,
  CSSCountCardHead,
} from '../../../style/components/card.styled';

const CountCard = (props) => {
  const {
    iconSrc = null,
    count = 0,
    countTitle = 'title',
    hasOtherItem = false,
    countTwo = 0,
    countTitleTwo = 'title',
  } = props;

  return (
    <CSSCountCard>
      {/* <CSSCountCardHead> */}
      <CSSCountCardHead className="flex-row-reverse">
        <CSSCardIcon>{iconSrc}</CSSCardIcon>
      </CSSCountCardHead>
      <CSSCountCardBody>
        <CSSCountCardBodyItem>
          <Typography variant="h5" component="div">
            {count}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {countTitle}
          </Typography>
        </CSSCountCardBodyItem>
        {hasOtherItem ? (
          <>
            <Divider orientation="vertical" flexItem />
            <CSSCountCardBodyItem>
              <Typography variant="h5" component="div">
                {countTwo}
              </Typography>
              <Typography color="text.secondary">{countTitleTwo}</Typography>
            </CSSCountCardBodyItem>
          </>
        ) : null}
      </CSSCountCardBody>
    </CSSCountCard>
  );
};

export default CountCard;

CountCard.propTypes = {
  iconSrc: PropTypes.node,
  count: PropTypes.number,
  countTitle: PropTypes.string,
  hasOtherItem: PropTypes.bool,
  countTwo: PropTypes.number,
  countTitleTwo: PropTypes.string,
};

// CountCard.defaultProps = {
//   iconSrc: {},
//   count: 0,
//   countTitle: 'title',
//   hasOtherItem: false,
//   countTwo: 0,
//   countTitleTwo: 'title',
// };
