import styled from '@emotion/styled';
import { Box, IconButton, Input } from '@mui/material';

const MenuBar = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 1rem;
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.shadows[1]};
  flex-wrap: wrap;
  gap:5px;
`;

const MenuActions = styled.span`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
`;

const Search = styled(Input)`
  padding: 0.2rem;
`;

const Filter = styled(IconButton)`
  color: ${({ theme }) => theme.colors.primary};
`;
const AppMenuBarStyle = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 1rem;
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.shadows[1]};
  flex-wrap: wrap;
  gap:5px;
`;

const AppMenuActionsStyle = styled.span`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
`;
export {
  MenuBar,
  MenuActions,
  Search,
  Filter,
  AppMenuBarStyle,
  AppMenuActionsStyle,
};
