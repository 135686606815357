import { createTheme } from '@mui/material';

const theme = createTheme({
  colors: {
    body: '#FAFAFA',
    typo: '#00000',
    background: '#FFFFFF',
    primary: '#1E90FC',
    lightest: '#FFFFFF',
  },
  borderRadius: '5px',
});

export default theme;

// ${({ theme }) => console.log('theme : ', theme)}
