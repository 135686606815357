/* eslint-disable */
import { Skeleton } from '@mui/material';
import React from 'react';

function ProfileDeatailloading() {
  return (
    <div>
      <div className="profile-display-details">
        <div className="profile_image">
          <Skeleton variant="circular" width={130} height={130} />
        </div>
        <span className="profile-user-name">
          <Skeleton variant="rounded" width={210} height={20} />
          <br />
          <Skeleton variant="rounded" width={210} height={20} />
          <br />
          <Skeleton variant="rounded" width={210} height={20} />
        </span>
      </div>
      <div className="profile-edit-detail">
        <div className="profile-edit-title">
          <span>
            <Skeleton variant="rectangular" width={210} height={30} />
          </span>
          <Skeleton variant="circular" width={40} height={40} />
        </div>
        <Skeleton variant="rounded" height={40} />
        <br />
        <Skeleton variant="rounded" height={40} />
        <br />
        <Skeleton variant="rounded" height={40} />
        <br />
      </div>
      <Skeleton variant="rounded" height={40} />
    </div>
  )
}

export default ProfileDeatailloading
