import { useContext, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { isEmpty, join, remove } from 'lodash';
import {
  Alert,
  Breadcrumbs,
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  // TextField,
} from '@mui/material';
import {
  Link,
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { AppMenuActionsStyle, AppMenuBarStyle } from '../../../style/components/controls.style';
import { MyContext } from '../../context/context';
import { getAppStatusChoiceApi, createdByApi } from '../../../apis/apis';
import TextField from '../../components/inputs/TextField.input';
import MultiSelect from './MultiSelect';

const ApplicationAppMenuBarStyle = () => {
  const {
    setAppError,
    hrChoice,
    setHrChoice,
    authorised,
    createApp,
    rejectDialog,
    setRejectDialog,
    approveDialog,
    setApproveDialog,
    deleteDialog,
    setDeleteDialog,
    restoreDialog,
    setRestoreDialog,
    archiveDialogControl,
    setArchiveDialogControllers,
    getApplicationListFunc,
    appFilterState,
    handleAppFilter,
    render,
    sendApplicationController,
    setSendApplicationController,
    reSendApplicationController,
    setReSendApplicationController,
  } = useContext(MyContext);
  const theme = useTheme();

  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const [appKey] = useSearchParams();
  const appStatus = appKey.get('applicationStatus');
  // const candidateId = appKey.get('candidateId');
  const applicationId = appKey.get('applicationId');
  const [statusChoice, setStatusChoice] = useState([
    {
      displayName: 'Loading...',
      value: 'null',
    },
  ]);

  const appLocation = useLocation();
  const appPath = appLocation.pathname;

  const handleFilterApiCall = () => {
    const objectToQueryStringArr = Object.entries(appFilterState).map((val) => {
      if (val[0] === 'role' && val[1] === 'All') {
        return null; // Exclude 'role=All' from the query string
      }
      if (!isEmpty(val[0] !== 'applicationCandidates' && val[1])) {
        return join(val, '=');
      }
      return null;
    });
    remove(objectToQueryStringArr, (item) => item === null);
    const queryString = join(objectToQueryStringArr, '&');
    let candidates = '';
    appFilterState.applicationCandidates.forEach((element) => {
      candidates += `applicationCandidates=${element.value}&`;
    });
    getApplicationListFunc(queryString.concat(`&${candidates}`));
  };

  const getChoicesOptions = async () => {
    const statusChoiceApiData = await getAppStatusChoiceApi();
    const apiRes = await createdByApi();

    if (statusChoiceApiData.success) setStatusChoice(statusChoiceApiData.data);
    if (apiRes.success) setHrChoice(apiRes.data);
  };

  useEffect(() => {
    getChoicesOptions();
    return () => {
      setArchiveDialogControllers(false);
      setRestoreDialog(false);
      setDeleteDialog(false);
      setRejectDialog(false);
      setApproveDialog(false);
      setReSendApplicationController(false);
      setSendApplicationController(false);
      setAppError(false);
    };
  }, []);

  useEffect(() => {
    handleFilterApiCall();
    return () => {
      setArchiveDialogControllers(false);
      setRestoreDialog(false);
      setDeleteDialog(false);
      setRejectDialog(false);
      setApproveDialog(false);
      setReSendApplicationController(false);
      setSendApplicationController(false);
      setAppError(false);
    };
  }, [render]);

  const isCreated = appStatus === 'created';
  const isRestored = appStatus === 'restored';
  const isRejected = appStatus === 'rejected';
  const isApproved = appStatus === 'approved';
  const isDeleted = appStatus === 'archived';
  // const isSubmitted = appStatus === 'submitted';
  const isDraft = appStatus === 'draft';
  const isDelivered = appStatus === 'delivered';
  const isStarted = appStatus === 'started';

  const editButton = () => {
    // if (authorised && !isSubmitted && !isRestored && !isRejected && !isDraft) {
    // if (applicationId) {
    if (applicationId && isDraft) {
      return (
        <Button
          onClick={async () => navigate({
            pathname: '/create-application',
            search: `?${createSearchParams(
              {
                appId: `${applicationId}`,
              },
              { state: { appId: `${applicationId}` } },
            )}`,
          })}
        >
          Edit
        </Button>
      );
    }
    return null;
  };

  const restoreButton = () => {
    // if (authorised && !isSubmitted && !isRestored && !isRejected && !isDraft) {
    if (authorised && !isRestored && isDeleted) {
      return (
        <Button onClick={() => setRestoreDialog(!restoreDialog)}>
          Restore
        </Button>
      );
    }
    return null;
  };
  const archiveButton = () => {
    // if (authorised && !isSubmitted && !isRestored && !isRejected && !isDraft) {
    if (authorised && !isDeleted && !isDraft) {
      return (
        <Button
          onClick={() => setArchiveDialogControllers(!archiveDialogControl)}
        >
          Archive
        </Button>
      );
    }
    return null;
  };
  const deleteButton = () => {
    if (!isDeleted) {
      return (
        <Button onClick={() => setDeleteDialog(!deleteDialog)}>Delete</Button>
      );
    }
    return null;
  };
  const rejectButton = () => {
    // if (!isCreated && !isRejected && !isDraft) {
    if (
      !isRejected
      && !isApproved
      && !isDeleted
      && !isCreated
      && !isDraft
      && !isDelivered
      && !isStarted
    ) {
      return (
        <Button onClick={() => setRejectDialog(!rejectDialog)}>Reject</Button>
      );
    }
    return null;
  };
  const approveButton = () => {
    // if (!isCreated && !isApproved && !isDraft) {
    if (
      !isApproved
      && !isRejected
      && !isDeleted
      && !isCreated
      && !isDraft
      && !isDelivered
      && !isStarted
    ) {
      return (
        <Button onClick={() => setApproveDialog(!approveDialog)}>
          Approve
        </Button>
      );
    }
    return null;
  };
  const sendApplication = () => {
    if (isDraft) {
      return (
        <Button
          onClick={() => setSendApplicationController(!sendApplicationController)}
        >
          Send application
        </Button>
      );
    }
    return null;
  };
  const reSendApplication = () => {
    if (isDelivered) {
      return (
        <Button
          onClick={() => setReSendApplicationController(!reSendApplicationController)}
        >
          Resend
        </Button>
      );
    }
    return null;
  };

  const executionErrorNote = () => {
    if (!authorised && isDeleted) {
      return (
        <Alert variant="filled" severity="error">
          Technical issues or parallel execution disrupted the operation
          request. Kindly revisit the application
        </Alert>
      );
    }
    return null;
  };

  return (
    <AppMenuBarStyle>
      <Breadcrumbs>
        <Link to="/application">Application</Link>
      </Breadcrumbs>
      <AppMenuActionsStyle>
        {appPath === '/application-details' ? (
          <ButtonGroup disableElevation variant="contained">
            {editButton()}
            {restoreButton()}
            {deleteButton()}
            {rejectButton()}
            {approveButton()}
            {reSendApplication()}
            {sendApplication()}
            {archiveButton()}
            {executionErrorNote()}
          </ButtonGroup>
        ) : null}
        {appPath === '/application' ? (
          <>
            <FormControl sx={{ minWidth: isSmallDevice ? '100%' : 160 }} size="small">
              <MultiSelect style={isSmallDevice ? '100%' : 300} />
            </FormControl>
            <FormControl sx={{ minWidth: isSmallDevice ? '100%' : 160 }} size="small">
              <TextField
                variant="outlined"
                size="small"
                name="applicationTag"
                id="applicationTagId"
                type="text"
                value={appFilterState.applicationTag}
                placeholder="Search By Tag"
                onChange={handleAppFilter}
                label="Application Tag"
                isHelperText={false}
              />
            </FormControl>
            <FormControl sx={{ minWidth: isSmallDevice ? '100%' : 160 }} size="small">
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                variant="outlined"
                name="applicationStatus"
                value={appFilterState.applicationStatus}
                label="Status"
                onChange={handleAppFilter}
              >
                {statusChoice.map((options) => {
                  const { label, value } = options;
                  const toDisplay = label === 'All' ? 'All' : label;
                  return (
                    <MenuItem value={value} key={value}>
                      {toDisplay}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {/* <Divider orientation="vertical" flexItem /> */}
            {authorised && (
              <FormControl sx={{ minWidth: isSmallDevice ? '100%' : 160 }} size="small">
                <InputLabel id="demo-simple-select-label">
                  Created By
                </InputLabel>
                <Select
                  name="createdBy"
                  value={appFilterState.createdBy}
                  label="Created By"
                  onChange={handleAppFilter}
                >
                  {hrChoice.map((options) => {
                    const { label, value } = options;
                    const getKey = value + Date.now();
                    return (
                      <MenuItem value={value} key={getKey}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
            {/* <Divider orientation="vertical" flexItem /> */}
            <Button
              variant="contained"
              disableElevation
              onClick={() => createApp()}
            >
              Create Application
            </Button>
          </>
        ) : null}
      </AppMenuActionsStyle>
    </AppMenuBarStyle>
  );
};

export default ApplicationAppMenuBarStyle;
