import { useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  FormLabel,
  Typography,
} from '@mui/material';
import { isEmpty, join, remove, startCase } from 'lodash';
import StartOutlinedIcon from '@mui/icons-material/StartOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import RestoreIcon from '@mui/icons-material/Restore';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
  CardActionFooter,
  CardContainer,
  CardHead,
  CardTopbar,
  CardWrapper,
  CreatedAt,
} from '../../../style/components/card.styled';
import ApplicationMenuBar from './MenuBar';
import AvatarFnmLnm from '../../../func/AvatarContent';
import { MyContext } from '../../context/context';
import ApplicationListSkeleton from './statistics/ApplicationListSkeleton';
import NoDataSVGFile from '../../../img/svg/NoDataSVGFile';

const applicationStatusHandler = (props) => {
  if (props?.value === 'created') {
    return (
      <Chip icon={<FactCheckOutlinedIcon />} label={props?.label} />
    );
  }
  if (props?.value === 'draft') {
    return <Chip icon={<PendingOutlinedIcon />} label={props?.label} />;
  }

  if (props?.value === 'started') {
    return <Chip icon={<StartOutlinedIcon />} label={props?.label} />;
  }

  if (props?.value === 'submitted') {
    return <Chip icon={<FactCheckOutlinedIcon />} label={props?.label} />;
  }

  if (props?.value === 'approved') {
    return <Chip icon={<VerifiedIcon />} label={props?.label} />;
  }

  if (props?.value === 'rejected') {
    return <Chip icon={<CancelOutlinedIcon />} label={props?.label} />;
  }

  if (props?.value === 'archived') {
    return <Chip icon={<ArchiveOutlinedIcon />} label={props?.label} />;
  }
  if (props?.value === 'restored') {
    return <Chip icon={<RestoreIcon />} label={props?.label} />;
  }
  if (props?.value === 'delivered') {
    return <Chip icon={<DoneAllIcon />} label={props?.label} />;
  }
  return null;
};

// const d = 'createdBy=64b6641bf174b919e00712d6';

const ApplicationList = () => {
  const {
    viewCandidateApp,
    applicationLists,
    loading,
    getApplicationListFunc,
    appFilterState,
    appFilterStrState,
  } = useContext(MyContext);

  const [resolutionController, setResolutionController] = useState(
    window.innerWidth,
  );

  useEffect(() => {
    // console.log("appFilterState",appFilterState)
    // getApplicationListFunc(appFilterStrState);
    // const resolutionRecorder = () => {
    //   setResolutionController(window.innerWidth);
    // };

    // /* Adding Event Listners for Scroll and Re-size */
    // /* for Scroll-to-Top and Resolution Controll */
    // window.addEventListener('resize', resolutionRecorder);

    // /* Dumping/Cleaning Up all task at end of useEffect */
    // /* Execution to avoid memory leak and Other issues */
    // return () => {
    //   window.removeEventListener('resize', resolutionRecorder);
    // };
    const objectToQueryStringArr = Object.entries(appFilterState).map((val) => {
      if (val[0] === 'role' && val[1] === 'All') {
        return null; // Exclude 'role=All' from the query string
      }
      if (!isEmpty(val[0] !== 'applicationCandidates' && val[1])) {
        return join(val, '=');
      }
      return null;
    });
    remove(objectToQueryStringArr, (item) => item === null);
    const queryString = join(objectToQueryStringArr, '&');
    let candidates = '';
    appFilterState.applicationCandidates.forEach((element) => {
      candidates += `applicationCandidates=${element.value}&`;
    });
    getApplicationListFunc(queryString.concat(`&${candidates}`));
  }, []);
  localStorage.clear();
  return (
    <section>
      <ApplicationMenuBar />
      <CardWrapper>
        {!loading ? (
          applicationLists?.map((item) => {
            const {
              applicationId,
              applicationTag,
              candidateId,
              firstname,
              lastname,
              applicationStatus,
              createdAt,
              active,
              status,
              email,
            } = item;
            return (
              <CardContainer
                key={applicationId}
                leftborder="true"
                status={
                  applicationStatus && !active ? 'archived' : applicationStatus
                }
                active={active.toString()}
              >
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      color: 'text.secondary'
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: "8px"
                      }}>
                      <AccessTimeIcon />
                      <Typography>{createdAt}</Typography>
                    </Box>
                    <Typography>{applicationTag}</Typography>
                  </Box>
                </Box>
                <CardHead
                  avatar={(
                    <Avatar>
                      {AvatarFnmLnm({
                        fnm: firstname,
                        lnm: lastname,
                      })}
                    </Avatar>
                  )}
                  title={startCase(`${firstname}  ${lastname}`)}
                  subheader={email}
                />
                <Divider />
                <CardActionFooter>
                  <>
                    {/* {applicationStatusHandler(
                      applicationStatus && !active
                        ? 'archived'
                        : applicationStatus,
                    )} */}
                    {applicationStatusHandler(status)}
                    <Button
                      variant="text"
                      onClick={() => viewCandidateApp({
                        applicationId,
                        candidateId,
                        applicationStatus,
                        active,
                      })}
                    >
                      View
                    </Button>
                  </>
                </CardActionFooter>
              </CardContainer>
            );
          })
        ) : (
          <ApplicationListSkeleton
            resolutionController={resolutionController}
          />
        )}
      </CardWrapper>
      {!loading && applicationLists.length <= 0 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <FormLabel
            sx={{
              color: 'error.main',
              fontWeight: '400',
              fontSize: '24px',
            }}
          >
            No Associated Data
          </FormLabel>
          <span>
            <NoDataSVGFile />
          </span>
        </div>
      )}
    </section>
  );
};

export default ApplicationList;
