import { useContext, useEffect, useState } from 'react';
import { isEmpty, join, remove } from 'lodash';
import {
  Breadcrumbs,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select as MUISelect,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@emotion/react';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import { MenuActions, MenuBar } from '../../../style/components/controls.style';
import { MyContext } from '../../context/context';
import { roleChoiceApi } from '../../../apis/apis';
import Select from '../../components/inputs/Select.input';
import UserForm from './UserForm';
import DilogControl from '../../components/Dialog/DilogControl';
import FlexedBox from '../../components/Other/FlexedBox';

const UserMenuBar = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { getUserListFunc, setDialogControl, authorised } = useContext(MyContext);
  const theme = useTheme();

  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const [filterData, setFilterData] = useState({
    role: '',
    status: '',
    name: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFilterData((prevData) => ({
      ...prevData,
      [name]: value === 'All' ? '' : value,
    }));
  };

  const handleSearch = (value) => {
    console.log('🚀 ~ handleSearch ~ value:', value);
    const queryString = `name=${value}`;
    getUserListFunc(queryString);
  };

  const [roleChoice, setRoleChoice] = useState([
    {
      displayName: 'Loading...',
      id: null,
    },
  ]);

  const getChoicesOptions = async () => {
    const roleChoiceApiData = await roleChoiceApi();
    const defaultOption = {
      value: 'all',
      label: 'All',
    };

    if (roleChoiceApiData.success) {
      setRoleChoice([defaultOption, ...roleChoiceApiData.data]);
      // setRoleChoice(roleChoiceApiData.data);
    }
  };

  const handleFilterApiCall = () => {
    const { role, status } = filterData;
    if (!isEmpty(role) || !isEmpty(status)) {
      const objectToQueryStringArr = Object.entries(filterData).map((val) => {
        if (val[0] === 'role' && isEmpty(val[1])) return null;
        if (!isEmpty(val[1])) return join(val, '=');
        return null;
      });

      remove(objectToQueryStringArr, (item) => item === null);

      const queryString = join(objectToQueryStringArr, '&');

      getUserListFunc(queryString);
    }
  };

  const [isInitial, setIsinitial] = useState(true);

  useEffect(() => {
    if (isInitial) {
      setFilterData((prevData) => ({
        ...prevData,
        status: 'true',
      }));
    }
    setIsinitial(false);
    handleFilterApiCall();
  }, [filterData]);

  useEffect(() => {
    getChoicesOptions();
  }, []);
  return (
    <MenuBar>
      <Breadcrumbs>
        <Link to="/user">User</Link>
      </Breadcrumbs>
      <MenuActions>
        <Paper
          variant="outlined"
          style={{ minWidth: isSmallDevice ? '100%' : 160, display: 'flex' }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search by Name..."
            name="name"
            id="name"
            value={filterData.name}
            onChange={(e) => {
              setFilterData((prevData) => ({
                ...prevData,
                [e.target.name]: e.target.value,
              }));
              handleSearch(e.target.value);
            }}
          />
          <IconButton
            type="button"
            sx={{ p: '8px' }}
            aria-label="search"
          // onClick={handleSearch}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
        {/* <Divider orientation="vertical" flexItem /> */}
        {authorised && (
          <FormControl sx={{ minWidth: isSmallDevice ? '100%' : 160 }} size="small">
            <Select
              id="applicationPasswordId"
              name="role"
              value={filterData.role || 'All'}
              label="Role"
              onChange={handleChange}
              options={roleChoice}
              size="small"
              className="min-w-180"
              selectLabel
            />
            {/* <Divider orientation="vertical" flexItem /> */}
          </FormControl>
        )}
        <FormControl sx={{ minWidth: isSmallDevice ? '100%' : 160 }} size="small">
          <InputLabel>Status</InputLabel>
          <MUISelect
            // value="true"
            defaultValue="true"
            name="status"
            // value={filterData.status}
            label="Status"
            onChange={handleChange}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="false">In Active</MenuItem>
            <MenuItem value="true" selected>Active</MenuItem>
          </MUISelect>
        </FormControl>
        {authorised && (
          <FormControl sx={{ minWidth: isSmallDevice ? '100%' : 10 }} size="small">
            {/* <Divider orientation="vertical" flexItem /> */}
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                setDialogControl(0);
                setIsDialogOpen(!isDialogOpen);
              }}
            >
              Add HR
            </Button>
          </FormControl>
        )}
        <DilogControl
          open={isDialogOpen}
          maxWidth="md"
          fullWidth
          title={(
            <FlexedBox
              direction="row"
              justifyContent="space-between"
              alignContent="center"
            >
              <span>Add HR</span>
              <IconButton
                sx={{ padding: '0px' }}
                onClick={() => setIsDialogOpen(!isDialogOpen)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </FlexedBox>
          )}
        >
          <UserForm
            isDialogOpen={isDialogOpen}
            setIsDialogOpen={setIsDialogOpen}
            data={{ isReachedData: false }}
          />
        </DilogControl>
      </MenuActions>
    </MenuBar>
  );
};

export default UserMenuBar;
