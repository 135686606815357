import styled from '@emotion/styled';

const ErrorWrap = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;
`;

const ErrorImg = styled.img`
  max-width: 400px;
`;

const ErrorTypo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ErrorStatus = styled.h1`
  font-size: 10rem;
  font-weight: 400;
`;

const ErrorTitle = styled.h2`
  font-size: 3rem;
`;

const ErrorCode = styled.h3`
  font-size: 2rem;
`;

const ErrorMessage = styled.p`
  font-size: 1.2rem;
`;

export {
  ErrorWrap,
  ErrorContent,
  ErrorImg,
  ErrorTypo,
  ErrorStatus,
  ErrorTitle,
  ErrorCode,
  ErrorMessage,
};
