import styled from '@emotion/styled';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ item }) => item}, 1fr);
  grid-column: ${({ colSpan }) => colSpan};
  gap: ${({ gap }) => `${gap}rem`};
  background-color: ${({ bg, theme }) => (bg ? theme.colors.background : null)};
`;

export default Grid;
