/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useRef, useState } from 'react';
import { TextField, Autocomplete, MenuItem } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { debounce } from 'lodash';
import { MyContext } from '../../context/context';
import { candidateListApi } from '../../../apis/apis';

const MultiSelect = ({ style = 300 }) => {
  const {
    appFilterState, setAppFilterState, setRender, render,
  } = useContext(MyContext);
  const [asyncOptions, setAsyncOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  async function handleCanidateDebouncing(optionSearchValue) {
    setLoading(true);
    const responseData = await candidateListApi(optionSearchValue);
    if (responseData.success) {
      setAsyncOptions(responseData.data);
      setLoading(false);
    }
    if (responseData.data.length <= 0) {
      setAsyncOptions([]);
    }
  }
  const candidateListDebounce = useRef(debounce(handleCanidateDebouncing, 500));

  const geListOfCandidates = async (props) => {
    candidateListDebounce.current(props);
  };

  return (
    <Autocomplete
      loading={loading}
      loadingText="Loading..."
      noOptionsText="No matching records exist"
      name="applicationCandidates"
      size="small"
      disableListWrap
      autoComplete
      disableCloseOnSelect
      disableClearable
      forcePopupIcon={false}
      value={appFilterState.applicationCandidates}
      defaultValue={[]}
      onClose={() => {
        setAsyncOptions([]);
      }}
      onChange={(event, data) => {
        setAppFilterState((prevData) => ({
          ...prevData,
          applicationCandidates: data,
        }));
        setRender(!render);
      }}
      // sx={{ m: 1, width: 300 }}
      sx={{ width: style }}
      multiple
      options={asyncOptions}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField
          {...params}
          // sx={{ m: 1, width: 300, height: 1 }}
          // sx={{ width: 300, height: 1 }}
          variant="outlined"
          label="Search Candidates"
          placeholder="Type candidate name"
          onChange={(event) => geListOfCandidates(event.target.value)}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <MenuItem
          {...props}
          key={option.value}
          value={option.value}
          sx={{ justifyContent: 'space-between' }}
        >
          {option.label}
          {selected ? <CheckIcon color="info" /> : null}
        </MenuItem>
      )}
    />
  );
};

export default MultiSelect;
