const createOnBoardApplicationFormInitialValues = {
  firstname: '',
  lastname: '',
  email: '',
  contactNo: '',
  password: '',
  confirmPassword: '',
};

export default createOnBoardApplicationFormInitialValues;
