import {
  FormControl as MUIFormControl,
  InputLabel as MUIInputLabel,
  Select as MUISelect,
  MenuItem as MUIOption,
  FormHelperText as MUIFormHelperText,
} from '@mui/material';
import PropTypes from 'prop-types';

const Select = (props) => {
  const {
    id = '',
    label = '',
    name = '',
    value = '',
    onChange = () => { },
    touched = false,
    errors = '',
    disabled = false,
    options,
    size = 'medium',
    className = '',
    selectLabel = false,
  } = props;
  return (
    <MUIFormControl size={size} className={className}>
      <MUIInputLabel error={touched && Boolean(errors)} id={`${id}-Label`}>
        {label}
      </MUIInputLabel>
      <MUISelect
        id={id}
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        error={touched && Boolean(errors)}
        disabled={disabled}
      >
        {options.map((obj) => (
          <MUIOption
            key={obj.value + Date.now()}
            value={selectLabel ? obj.label : obj.value}
          >
            {obj.label}
          </MUIOption>
        ))}
      </MUISelect>
      {touched && errors && (
        <MUIFormHelperText error>{errors}</MUIFormHelperText>
      )}
    </MUIFormControl>
  );
};

Select.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.string,
  touched: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.instanceOf(Array).isRequired,
  size: PropTypes.string,
  className: PropTypes.string,
  selectLabel: PropTypes.bool,
};

export default Select;
