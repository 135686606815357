/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { BorderColorOutlined, CreateOutlined } from '@mui/icons-material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import LoadingSpinner from '../../components/Loader/LoadingSpinner';
import { adminProfileAPI, adminUpdateProfileAPI, updateAdminProfileImage } from '../../../apis/apis';
import showSnackbar from '../../components/Message/showSnackbar';
import ProfileDeatailloading from './ProfileDeatailloading';
import TextField from '../../components/inputs/TextField.input';
import { CustomCard } from '../../../style/components/card.styled';
import Button from '../../components/inputs/Button.input';
import './profile.css';
import ResetPassword from './ResetPassword';

const Profile = () => {
  const validationSchema = Yup.object({
    firstname: Yup.string().required('First Name is required'),
    lastname: Yup.string().required('Last Name is required'),
    contactNo: Yup.string().required('Contact Number is required')
      .matches(/^[0-9]{10}$/, 'Contact Number must be 10 digits'),
  });

  const [pageLoading, setPageLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    firstname: '',
    lastname: '',
    contactNo: '',
  });
  const [displayProfileDetail, setDisplayProfileDetail] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [reload, setReload] = useState(false);
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const handleSubmit = async (values) => {
    setSaveBtnLoading(true);
    try {
      const res = await adminUpdateProfileAPI(values);
      if (res.status === 200) {
        showSnackbar(res.data.message, { variant: 'success' });
        setReload(!reload);
        setIsEditProfile(false);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        showSnackbar(err?.response?.data?.message, { variant: 'error' });
      }
    }
    setSaveBtnLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setPageLoading(true);
      try {
        const res = await adminProfileAPI();
        if (res.status === 200) {
          const { data } = res.data;
          setInitialValues({
            firstname: data.firstname,
            lastname: data.lastname,
            contactNo: data.contactNo,
          });
          setDisplayProfileDetail({
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.email,
            role: data.role,
          });
          setProfileImageUrl(data.profileImageUrl);
        }
      } catch (err) {
        if (err?.response?.data?.message) {
          showSnackbar(err?.response?.data?.message, { variant: 'error' });
        }
      }
      setPageLoading(false);
    };
    fetchData();
  }, [reload]);

  const handleProfileImage = async (event) => {
    const file = event.target.files[0];
    const allowedFileType = ['image/jpeg', 'image/png'];
    if (file && !allowedFileType.includes(file.type)) {
      showSnackbar('Please select a JPEG or PNG.', { variant: 'error' });
      return;
    }
    const maxSize = 5 * 1024 * 1024;
    if (file && file.size > maxSize) {
      showSnackbar('File size exceeds the maximum limit (5MB).', { variant: 'error' });
      return;
    }
    if (file) {
      setProfileImageUrl(URL.createObjectURL(file));
      const formData = new FormData();
      formData.append('profileImage', file);
      try {
        const res = await updateAdminProfileImage(formData);
        if (res.status === 200) {
          showSnackbar(res.data.message, { variant: 'success' });
        }
      } catch (err) {
        if (err?.response?.data?.message) {
          showSnackbar(err?.response?.data?.message, { variant: 'error' });
          setReload(!reload);
        }
      }
    }
  };

  const handleCancleEditProfile = () => setTimeout(() => setIsEditProfile(false), 500);

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 4, sm: 2, md: 3 }}>
      <Grid xs={12} md={6}>
        <CustomCard sx={{ m: 1, mt: 3 }}>
          {pageLoading ? (
            <ProfileDeatailloading />
          ) : (
            <>
              <div className="profile-display-details">
                <div className="profile_image">
                  <a href={profileImageUrl} target="_blank" rel="noopener noreferrer">
                    <img
                      src={profileImageUrl}
                      alt="Profile"
                      className="profile_image"
                      loading="lazy"
                    />
                  </a>
                  <label htmlFor="profile_image_input">
                    <i className="edit_icon">
                      <CreateOutlined fontSize="small" />
                    </i>
                    <input
                      id="profile_image_input"
                      type="file"
                      onChange={handleProfileImage}
                      hidden
                    />
                  </label>
                </div>
                <span className="profile-user-name">
                  {displayProfileDetail && `${displayProfileDetail.firstname} ${displayProfileDetail.lastname}`}
                </span>
                <p>{displayProfileDetail && displayProfileDetail.email}</p>
                <p>{displayProfileDetail && displayProfileDetail.role}</p>
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({
                  values,
                  handleChange,
                  touched,
                  errors,
                }) => (
                  <Form className="profile-edit-detail">
                    <div className="profile-edit-title">
                      <span>Profile</span>
                      <BorderColorOutlined
                        className={isEditProfile ? 'profile-edit-icon' : ''}
                        onClick={() => setIsEditProfile(!isEditProfile)}
                      />
                    </div>
                    <TextField
                      id="fname"
                      label="First Name"
                      placeholder="First Name"
                      name="firstname"
                      variant="outlined"
                      value={values.firstname}
                      onChange={handleChange}
                      touched={touched.firstname}
                      errors={errors.firstname}
                      disabled={!isEditProfile}
                    />
                    <TextField
                      id="lname"
                      label="Last Name"
                      placeholder="Last Name"
                      name="lastname"
                      variant="outlined"
                      value={values.lastname}
                      onChange={handleChange}
                      touched={touched.lastname}
                      errors={errors.lastname}
                      disabled={!isEditProfile}
                    />
                    <TextField
                      id="contactNo"
                      label="Contact Number"
                      placeholder="Contact Number"
                      name="contactNo"
                      variant="outlined"
                      value={values.contactNo}
                      onChange={handleChange}
                      touched={touched.contactNo}
                      errors={errors.contactNo}
                      disabled={!isEditProfile}
                    />
                    <div className="profile-edit-action">
                      {isEditProfile && (
                        <>
                          <Button
                            type="submit"
                            disabled={saveBtnLoading}
                          >
                            {saveBtnLoading && <LoadingSpinner />}
                            Save
                          </Button>
                          <div
                            onClick={handleCancleEditProfile}
                          >
                            <Button
                              variant="outlined"
                              type="reset"
                            >
                              Cancel
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </CustomCard>
      </Grid>
      <Grid xs={12} md={6}>
        <CustomCard sx={{ m: 1, mt: 3 }}>
          <ResetPassword />
        </CustomCard>
      </Grid>
    </Grid>
  );
}

export default Profile;
