/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import {
  Alert,
  Button,
  FormHelperText,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import { enqueueSnackbar } from 'notistack';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import {
  AuthForm,
  AuthInputs,
  AuthItem,
  AuthTypo,
  AuthWrapper,
} from './login.style';
import Statics from '../../../img/imgs';
import { addNewPassword, verifyPasswordLink } from '../../../apis/apis';
import { PasswordFiled } from '../../components/inputs/PasswordFiled';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CardForMessage from '../../components/Other/CardForMessage';
import showSnackbar from '../../components/Message/showSnackbar';

const NewPassWord = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const [serverResponseMessage, setServerResponseMessage] = useState('');
  const [searchParams] = useSearchParams();
  const [disableAll, setDisableAll] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function verifyLink() {
      const data = {
        key: searchParams.get('key'),
        flag: searchParams.get('flag'),
      };
      const responseData = await verifyPasswordLink(data);
      if (!responseData.success) {
        setServerResponseMessage(responseData.message);
        showSnackbar(responseData?.message, { variant: 'error' })
        setDisableAll(true);
      }

    }
    verifyLink();
  }, []);

  return (
    <AuthWrapper>
      <AuthItem bg>
        <img src={Statics.logo} alt='ConversantechLogo' />
      </AuthItem>
      <AuthItem>
        {disableAll ? (
          <div className='error-snippet'>
            <CardForMessage
              blockSpacing={{
                maxWidth: 350,
                borderRadius: '2rem',
                padding: '0.35rem',
              }}
              customContentClass='error-snippet'
              typoVariant='body1'
              typoColor='text.secondary'
              typoTextAlign='justify'
              customActionClass='error-snippet'
              buttonVariant='outlined'
              buttonOnClick={() => {
                console.log('first');
                navigate('/login');
              }}
              icon={<UnsubscribeIcon fontSize='large' color='error' />}
              message={serverResponseMessage && serverResponseMessage}
            />
          </div>
        ) : (
          <Formik
            initialValues={{ newPassword: '', confirmPassword: '' }}
            validationSchema={Yup.object({
              newPassword: Yup.string().required(
                'Please Add your New Password!'
              ),
              confirmPassword: Yup.string()
                .required('Please Add your Confirm Password!')
                .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
            })}
            onSubmit={async (value) => {
              const data = {
                newpassword: value.newPassword,
                key: searchParams.get('key'),
                flag: searchParams.get('flag'),
              };
              const responseData = await addNewPassword(data);
              if (responseData.success) {
                showSnackbar(responseData?.message, {
                  variant: 'success',
                  autoHideDuration: 5000,
                });
                navigate('/login');
              } else {
                showSnackbar(responseData?.message, { variant: 'error' });
                setServerResponseMessage(responseData.message);
              }
            }}
          >
            {(props) => {
              const { values, handleChange, touched, errors } = props;
              return (
                <AuthForm>
                  <AuthTypo>
                    <Typography className='login-title'>
                      Create New Password
                    </Typography>
                    <Typography className='login-subtitle'>
                      Change Password
                    </Typography>
                  </AuthTypo>
                  <AuthInputs>
                    {/* {serverResponseMessage && (
                      <Alert severity='error'>{serverResponseMessage}</Alert>
                    )} */}
                    <PasswordFiled
                      data-testid='newPassword'
                      label='Password'
                      mode={showPassword}
                      id='newPassword'
                      name='newPassword'
                      value={values.newPassword}
                      onChange={handleChange}
                      iconOnClick={handleClickShowPassword}
                      error={touched.newPassword && Boolean(errors.newPassword)}
                      errorMessage={
                        <FormHelperText style={{ minHeight: '24px' }} error>
                          {touched.newPassword && errors.newPassword}
                        </FormHelperText>
                      }
                    />
                    <PasswordFiled
                      data-testid='confirmPasswordId'
                      label='Confirm Password'
                      mode={showConfirmPassword}
                      id='confirmPasswordId'
                      name='confirmPassword'
                      value={values.confirmPassword}
                      onChange={handleChange}
                      iconOnClick={handleClickShowConfirmPassword}
                      error={
                        touched.confirmPassword &&
                        Boolean(errors.confirmPassword)
                      }
                      errorMessage={
                        <FormHelperText style={{ minHeight: '24px' }} error>
                          {touched.confirmPassword && errors.confirmPassword}
                        </FormHelperText>
                      }
                    />
                  </AuthInputs>
                  <Button
                    data-testid='createPassword'
                    id='createPassword'
                    type='submit'
                    variant='contained'
                    name='createPasswordName'
                  >
                    Create Password
                  </Button>
                </AuthForm>
              );
            }}
          </Formik>
        )}
      </AuthItem>
    </AuthWrapper>
  );
};

export default NewPassWord;
