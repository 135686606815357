// /* eslint-disable */
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { Formik } from 'formik';
import { MyContext } from '../../context/context';
import {
  AuthForm,
  AuthInputs,
  AuthItem,
  AuthTypo,
  AuthWrapper,
} from './login.style';
import Statics from '../../../img/imgs';
import loginValidationSchema from './loginValidationSchema';
import Button from '../../components/inputs/Button.input';
import TextField from '../../components/inputs/TextField.input';
import LoadingSpinner from '../../components/Loader/LoadingSpinner';
import { isLoggedinApi } from '../../../apis/apis';

const Login = () => {
  const { loginInitVal, handleLogin } = useContext(MyContext);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (values) => {
    setLoading(true);
    await handleLogin(values);
    setLoading(false);
  };
  const navigate = useNavigate();
  
  useEffect(() => {
    const checkIsLogin = async () => {
      try {
        const response = await isLoggedinApi();
        if (response.status === 200) {
          navigate("/");
        }
      } catch (error) { }
    }
    checkIsLogin();
  }, []);

  return (
    <AuthWrapper>
      <AuthItem bg>
        <img src={Statics.logo} alt="ConversantechLogo" />
      </AuthItem>
      <AuthItem>
        <Formik
          initialValues={loginInitVal}
          validationSchema={loginValidationSchema}
          onSubmit={handleSubmit}
        // onSubmit={(value) => handleLogin(value)}
        >
          {(props) => {
            const {
              values, handleChange, touched, errors,
            } = props;
            return (
              <AuthForm>
                <AuthTypo>
                  <Typography className="login-title">Sign In</Typography>
                  <Typography className="login-subtitle">
                    Greetings once more, Please enter your credentials.
                  </Typography>
                </AuthTypo>
                <AuthInputs>
                  <TextField
                    id="loginEmail"
                    type="email"
                    label="Email"
                    placeholder="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    touched={touched.email}
                    errors={errors.email}
                  />
                  <TextField
                    id="loginPassword"
                    type="password"
                    label="Password"
                    placeholder="Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    variant="standard"
                    touched={touched.password}
                    errors={errors.password}
                  />
                  <Link to="/resest-password">Forgot your password?</Link>
                </AuthInputs>
                <Button
                  id="applicationLoginButton"
                  type="submit"
                  disabled={loading}
                >
                  {loading && <LoadingSpinner />}
                  Login
                </Button>
              </AuthForm>
            );
          }}
        </Formik>
      </AuthItem>
    </AuthWrapper>
  );
};

export default Login;
