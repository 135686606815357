import styled from '@emotion/styled';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Menu,
  Typography,
} from '@mui/material';

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  @media only screen and (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`;

const CardContainer = styled(Card)`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background};
  /* background-color: red; */
  padding: 1rem;
  box-shadow: ${({ theme }) => theme.shadows[0]};

  &:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  ${({ leftborder, active, status }) => {
    if (leftborder && status === 'rejected') {
      return 'border-left: 0.3rem solid red';
    }
    if (leftborder && active === 'false') {
      return 'border-left: 0.3rem solid gray';
    }

    return 'border-left: 0.3rem solid white';
  }}
`;

const CardTopbar = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > button {
    padding: 0;
  }
`;

const CardMenuPopUp = styled(Menu)``;

const CardHead = styled(CardHeader)`
  padding: 0;
  cursor: pointer;
`;

const CreatedAt = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: gray;
`;

const CardActionFooter = styled(CardActions)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
`;

const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PopupWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  position: absolute;
  display: ${({ active }) => (active ? 'block' : 'none')};
  flex-direction: column;
  gap: 0.4rem;
  background-color: whitesmoke;
  transform: translateY(70%);
  z-index: 9999;
  padding: 0.4rem;
  box-shadow: ${({ theme }) => theme.shadows[2]};
  border-radius: 4px;
`;

const PopupChips = styled(Chip)`
  background-color: inherit;
  padding: 0;
`;
const PopupDescription = styled(Typography)`
  font-size: 0.9rem;
  padding-inline: 0.5rem;
  margin-block: 0.2rem;
`;

const CSSCountCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  box-shadow: ${({ theme }) => theme.shadows[0]};
`;

const CSSCountCardHead = styled.div`
  padding: 0;
`;

const CSSCountCardBody = styled(CardContent)`
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  padding: 0 !important;
`;

const CSSCountCardBodyItem = styled.div`
  width: 100%;
`;

const CSSCardIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  aspect-ratio: 1/1;
  width: 3rem;
  background-color: #f1f1f1;
  border-radius: 6px;
`;
const CustomCard = styled(Card)`
  background-color: ${({ theme }) => theme.colors.background};
  padding: 1rem;
  box-shadow: ${({ theme }) => theme.shadows[0]};

  &:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  border:0.5px solid rgba(0, 0, 0, 0.2);
  border-radius:15px;
  padding:24px
`;
export {
  CSSCardIcon,
  CardWrapper,
  CardContainer,
  CardTopbar,
  CardMenuPopUp,
  CardHead,
  CreatedAt,
  CardActionFooter,
  CardFooter,
  PopupWrapper,
  PopupChips,
  PopupDescription,
  CSSCountCard,
  CSSCountCardHead,
  CSSCountCardBody,
  CSSCountCardBodyItem,
  CustomCard,
};

/* border-bottom: ${({ brdbtm }) => (brdbtm ? '0.3rem solid black' : null)}; */
