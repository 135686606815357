/* eslint-disable no-param-reassign */
import { useContext, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  DialogActions,
  FormControlLabel,
  IconButton,
  Snackbar,
  Switch,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Formik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import Grid from '../../../style/utils/grid.style';
import createUserFormValidation from './statistics/validation';
import { MyContext } from '../../context/context';
import TextField from '../../components/inputs/TextField.input';
import error404 from '../../../img/svg/error404.svg';
import { ErrorImg } from '../error/error.style';
import FlexedBox from '../../components/Other/FlexedBox';
import { Spineer } from '../../../style/components/Spinner.styled';
import { PasswordFiled } from '../../components/inputs/PasswordFiled';
import showSnackbar from '../../components/Message/showSnackbar';

const UserForm = (exProps) => {
  const {
    userFormInitVal,
    handleUserForm,
    dialogControl,
    getUser,
    updateUser,
    render,
    setRender,
  } = useContext(MyContext);

  const [loading, setLoading] = useState(true);
  const [singleUserData, setSingleUserData] = useState();
  const [spinnerLoader, setSpinnerLoader] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isSnackbar, setIsSnackbar] = useState({
    active: false,
    success: true,
    message: 'Conversantech',
  });
  const [errorHandler, setErrorHandler] = useState({
    error: false,
    message: '',
  });

  const handleClose = () => {
    setIsSnackbar((isSnackbar.active = false));
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    async function getData() {
      const { data } = exProps;
      const fetchData = await getUser(data.userId);
      if (fetchData.success) {
        setSingleUserData(fetchData);
        setLoading(false);
      }
      if (!fetchData.success) {
        setErrorHandler(() => ({
          error: true,
          message: fetchData.message,
        }));
      }
    }
    if (dialogControl) {
      getData();
    } else {
      setLoading(false);
    }
  }, []);

  const handleUserFormSubmit = async (values) => {
    if (!dialogControl) {
      setSpinnerLoader(true);
      const responseData = await handleUserForm(values);
      if (responseData.success) {
        setSpinnerLoader(false);
        showSnackbar(responseData.message, {
          variant: 'success',
        });
        setRender(!render);
        const { isDialogOpen, setIsDialogOpen } = exProps;
        setIsDialogOpen(!isDialogOpen);
      }
      if (!responseData.success) {
        setSpinnerLoader(false);
        showSnackbar(responseData.message, {
          variant: 'error',
        });
      }
    }
    if (dialogControl) {
      values.userId = singleUserData.data.id;
      if (!values.passwordController) {
        delete values.password;
      }
      setSpinnerLoader(true);
      const responseData = await updateUser(values);
      if (responseData.success) {
        setRender(!render);
        const { setIsDialogOpen, isDialogOpen } = exProps;
        setIsDialogOpen(!isDialogOpen);
      }
      if (!responseData.success) {
        setSpinnerLoader(false);
        enqueueSnackbar(responseData.message, {
          variant: 'error',
          autoHideDuration: 5000,
        });
      }
    }
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
  return (
    <section>
      {errorHandler.error ? (
        <div>
          <Alert variant="filled" severity="error">
            {errorHandler.message}
          </Alert>
          <div className="error-snippet">
            <ErrorImg src={error404} alt="not found" />
          </div>
        </div>
      ) : null}
      {!loading && (
        <Formik
          initialValues={
            !dialogControl ? userFormInitVal : singleUserData.data.data
          }
          validationSchema={createUserFormValidation}
          onSubmit={(values) => {
            handleUserFormSubmit(values);
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              submitForm,
            } = props;
            return (
              <>
                <FlexedBox
                  justifyContent="space-between"
                  alignItems="center"
                  gap="1rem"
                >
                  <Typography sx={{ color: 'error.main' }}>
                    {dialogControl ? (
                      <span>
                        {!singleUserData.data.data.active
                          && !values.active
                          && 'All the access of this user was revoked'}
                        {singleUserData.data.data.active
                          && !values.active
                          && 'All the access of this user will be revoked'}
                      </span>
                    ) : null}
                  </Typography>
                  <FormControlLabel
                    control={(
                      <Switch
                        checked={values.active}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        id="activeId"
                        name="active"
                      />
                    )}
                    label={!values.active ? 'Deactivate User' : 'Active User'}
                  />
                </FlexedBox>
                <Grid item={2} gap={1} rowGap={0.5}>
                  <TextField
                    variant="outlined"
                    id="userFirstNameId"
                    label="Firstname"
                    placeholder="Firstname"
                    name="firstname"
                    value={values.firstname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.firstname}
                    touched={touched.firstname}
                  />
                  <TextField
                    variant="outlined"
                    id="userLastnameId"
                    label="Lastname"
                    name="lastname"
                    value={values.lastname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.lastname}
                    touched={touched.lastname}
                  />
                  <TextField
                    variant="outlined"
                    id="applicationEmailId"
                    type="email"
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.email}
                    errors={errors.email}
                    className="col-1-span-2"
                  />
                  {/* <Select
                    id="applicationPasswordId"
                    name="role"
                    value={values.role}
                    label="Role"
                    onChange={handleChange}
                    errors={errors.role}
                    touched={touched.role}
                    options={roleChoices}
                    selectLabel
                  /> */}
                  {dialogControl ? (
                    <div className="col-1-span-2">
                      <FlexedBox
                        direction="row"
                        justifyContent="flex-start"
                        alignContent="center"
                        marginLeft={1}
                      >
                        <FormControlLabel
                          control={(
                            <Switch
                              id="passwordController"
                              name="passwordController"
                              size="small"
                              value="checked"
                              inputProps={{ 'aria-label': 'controlled' }}
                              checked={values.passwordController}
                              onChange={handleChange}
                            />
                          )}
                          label={values.passwordController && 'New Password'}
                        />
                        <Typography sx={{ color: 'warning.main' }}>
                          {!dialogControl && (
                            <span>
                              {!dialogControl
                                && !values.passwordController
                                && "If you don't set a password, users must add one via the verification email before logging in"}
                            </span>
                          )}
                          {dialogControl && values.passwordController ? (
                            <span>
                              New password will be set, User will be notified
                              via email
                            </span>
                          ) : null}
                        </Typography>
                      </FlexedBox>

                      {/* <TextField
                        variant="outlined"
                        id="applicationPasswordId"
                        type="password"
                        label="Set new password"
                        name="password"
                        value={
                        values.passwordController && values.password
                          ? values.password
                          : ''
                      }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.password}
                        errors={errors.password}
                        disabled={!values.passwordController}
                      /> */}

                      <PasswordFiled
                        label="Set new password"
                        mode={showConfirmPassword}
                        id="applicationPasswordId"
                        name="password"
                        value={
                          values.passwordController && values.password
                            ? values.password
                            : ''
                        }
                        disabled={!values.passwordController}
                        onChange={handleChange}
                        iconOnClick={handleClickShowConfirmPassword}
                        error={touched.password && Boolean(errors.password)}
                      />
                    </div>
                  ) : null}
                  <DialogActions className="col-1-span-2">
                    <FlexedBox justifyContent="flex-end">
                      <Button
                        type="submit"
                        variant="contained"
                        disableElevation
                        onClick={() => submitForm()}
                        disabled={spinnerLoader}
                      >
                        Submit
                        {spinnerLoader && <Spineer />}
                      </Button>
                    </FlexedBox>
                  </DialogActions>
                </Grid>
              </>
            );
          }}
        </Formik>
      )}
      {/* Submit Button */}
      <Snackbar
        open={isSnackbar.active}
        autoHideDuration={3000}
        message={isSnackbar.message}
        onClose={handleClose}
        action={action}
      />
    </section>
  );
};

// UserForm.propTypes = {
//   values: PropTypes.shape({
//     firstname: PropTypes.string,
//     lastname: PropTypes.string,
//     email: PropTypes.string,
//     contact_no: PropTypes.string,
//     password: PropTypes.string,
//     role: PropTypes.string,
//     active: PropTypes.bool,
//     passwordController: PropTypes.bool,
//   }),
//   handleChange: PropTypes.func,
//   handleBlur: PropTypes.func,
//   submitForm: PropTypes.func,
//   touched: PropTypes.bool,
//   errors: PropTypes.bool,
// };
// UserForm.defaultProps = {
//   values: {
//     firstname: '',
//     lastname: '',
//     email: '',
//     contact_no: '',
//     password: '',
//     role: '',
//   },
//   handleChange: () => { },
//   handleBlur: () => { },
//   submitForm: () => { },
//   touched: false,
//   errors: false,
// };

export default UserForm;
