const createUserFormInitialValues = {
  active: true,
  userId: '',
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  role: '',
  passwordController: false,
};

export default createUserFormInitialValues;
