import styled from '@emotion/styled';
import { Form } from 'formik';

const AuthWrapper = styled.div`
  display: grid;
  grid-template-columns: 30% auto;

  @media only screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
  }
`;

const AuthItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: ${({ bg }) => (bg ? 'white' : null)};

  img {
    max-width:300px;
  }

  @media only screen and (max-width: 1200px) {
    width: auto;
    height: auto;
    background-color: transparent;
    gap:10px;
  }
`;

const AuthForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 1rem;
  max-width: 500px;
  @media only screen and (max-width: 800px) {
    gap: 2rem;
    padding: 2rem;
  }
  @media only screen and (max-width: 800px) {
    gap: 2rem;
    padding: 1.5rem;
  }
`;

/**  */
const AuthTypo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .login-title {
    position: relative;
    font-size: 3rem;
  }
  .login-title::before {
    content: '';
    position: absolute;
    inset: 100% 0;
    width: 20%;
    height: 6px;
    background-image: linear-gradient(to right, #4481eb 0%, #04befe 100%);
    transform: skew(45deg);
    border-radius: 20px;
  }
  .login-subtitle {
    font-size: 1.2rem;
  }
  @media only screen and (max-width: 800px) {
    .login-title {
      position: relative;
      font-size: 2.5rem;
    }
    .login-subtitle {
      font-size: 1rem;
    }
  }
  @media only screen and (max-width:600px){
    .login-title {
      font-size:1.9rem;
      font-weight:bold;
    }
  }
`;

const AuthInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  align-items: flex-end;

  & > div {
    width: 100%;
  }

  & > a {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export {
  AuthTypo,
  AuthInputs,
  AuthWrapper,
  AuthItem,
  AuthForm,
};
