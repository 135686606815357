import { Route, Routes } from 'react-router-dom';
import Container from '../../style/base/container.style';
import Dashboard from '../pages/dashboard/Dashboard';
import ApplicationList from '../pages/application/List';
import ApplicationForm from '../pages/application/ApplicationForm';
import ApplicationDetails from '../pages/application/Details';
import UserList from '../pages/user/List';
import Profile from '../pages/Profile/Profile';
// import UserForm from '../pages/user/UserForm';

const Main = () => (
  <main>
    <Container>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="application" element={<ApplicationList />} />
        <Route path="/create-application" element={<ApplicationForm />} />
        <Route path="application-details" element={<ApplicationDetails />} />
        <Route path="user" element={<UserList />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
    </Container>
  </main>
);

export default Main;
