import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ErrorContent,
  ErrorImg,
  ErrorTypo,
  ErrorWrap,
  ErrorStatus,
  ErrorMessage,
  ErrorCode,
} from './error.style';

import noConnectionImg from '../../../img/svg/no-connection.svg';
import { MyContext } from '../../context/context';

const handleErrorImg = () => (
  <ErrorImg src={noConnectionImg} alt="No Connection" />
);

const Error = () => {
  const navigate = useNavigate();
  const { error } = useContext(MyContext);
  const {
    isError,
    status,
    code,
    message,
  } = error;

  useEffect(() => {
    !isError && navigate('/');
  }, []);

  return (
    <ErrorWrap>
      <ErrorContent>
        {handleErrorImg(error)}
        <ErrorTypo>
          <ErrorStatus>{status}</ErrorStatus>
          <ErrorCode>{code}</ErrorCode>
          <ErrorMessage>{message}</ErrorMessage>
        </ErrorTypo>
      </ErrorContent>
    </ErrorWrap>
  );
};

export default Error;
